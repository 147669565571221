<template>
    <div>
        <p v-if="hintText">{{ hintText }}</p>
        <v-text-field
            clearable
            type="number"
            v-model="answer"
            :rules="rules"
            name="numeric"
            inputmode="numeric"
            @blur="updateNumericAnswer"
            :placeholder="$t(placeholderText)"/>
    </div>
</template>

<script>
import FieldTypes from '../../../mixins/FieldTypes';

export default {
    name: 'Numeric',
    props: {
        min: {
            required: true,
            default: 0
        },
        max: {
            required: true
        },
        placeholderText: {
            default: 'fields.numeric.add_value'
        },
        isRequired: {
            default: true
        },
        questionId: {}
    },
    data() {
        return {
            answer: ''
        };
    },
    mixins: [FieldTypes],
    computed: {
        rules() {
            if (this.isRequired && this.min && this.max) {
                return [
                    value => !!value || this.$t('fields.general.rules.required'),
                    value => value >= this.min || this.$t('fields.general.rules.min', {min: this.min}),
                    value => value <= this.max || this.$t('fields.general.rules.max', {max: this.max})
                ];
            } else if (this.isRequired && this.min) {
                return [
                    value => !!value || this.$t('fields.general.rules.required'),
                    value => value >= this.min || this.$t('fields.general.rules.min', {min: this.min})
                ];
            } else if (this.isRequired && this.max) {
                return [
                    value => !!value || this.$t('fields.general.rules.required'),
                    value => value <= this.max || this.$t('fields.general.rules.max', {max: this.max})
                ];
            } else {
                return [];
            }
        },
        hintText() {
            if (this.min && this.max) {
                return this.$t('fields.numeric.hint_min_and_max', {min: this.min, max: this.max});
            }
            if (!this.min && this.max) {
                return this.$t('fields.numeric.hint_max', {max: this.max});
            }
            if (this.min && !this.max) {
                return this.$t('fields.numeric.hint_min', {min: this.min});
            }
            return '';
        }
    }
};
</script>
